import { Feeder } from "../../api/interfaces";

interface FeederPlatformProps {
  feeder: Feeder;
}

export const FeederPlatform = ({ feeder }: FeederPlatformProps) => {
  let state = null;

  switch (feeder.controller.platformState) {
    case "STABLE":
      state = <span className="text-orange-500">Occupied</span>;
      break;
    case "EMPTY":
      state = <span className="text-green-500">Clear</span>;
      break;
    default:
      state = (
        <span className="text-green-500">
          {feeder.controller.platformState}
        </span>
      );
  }

  return (
    <span className=" text-sm">
      <span className="font-semibold">Platform:</span>{" "}
      {feeder.controller.platformWeight} grams ({state})
    </span>
  );
};

export default FeederPlatform;
