import { Feeder } from "../../api/interfaces";

interface FeederRFIDProps {
  feeder: Feeder;
}

export const FeederRFID = ({ feeder }: FeederRFIDProps) => {
  const mof = feeder.controller.rfidMOF / 10;

  let mofDisplay = (
    <span>
      <span className="">MOF:</span>{" "}
      <span className="text-green-500">{mof}</span>
    </span>
  );

  if (mof < 115 || mof > 135) {
    mofDisplay = (
      <span>
        {" "}
        <span className="">MOF:</span>{" "}
        <span className="text-red-500"> {mof} OOR</span>
      </span>
    );
  }

  let state = null;

  switch (feeder.controller.rfidState) {
    case "DISABLED":
      state = <span className="text-orange-500">Disabled</span>;
      break;
    case "ENABLED":
      state = <span className="text-green-500">Enabled</span>;
      break;
    case "ERROR":
      state = <span className="text-red-500">Error</span>;
      break;
  }

  return (
    <span className="text-sm">
      <span className="font-semibold">RFID:</span>{" "}
      {feeder.controller.rfidTag ? feeder.controller.rfidTag : ""} {state} (
      {mofDisplay})
    </span>
  );
};

export default FeederRFID;
