import { Feeder } from "../../api/interfaces";

interface FeederOnlineStatusProps {
  feeder: Feeder;
}

export const FeederOnlineStatus = ({ feeder }: FeederOnlineStatusProps) => {
  if (!feeder.controller) {
    return <div></div>;
  }

  let colour = "text-red-500 dark:text-red-500";
  if (feeder.controller.isOnline) {
    colour = "text-green-500 dark:text-green-500";
  }

  return (
    <div
      className={`text-sm inline-flex items-center text-base font-semibold ${colour}`}
    >
      {feeder.controller.isOnline ? "ONLINE" : "OFFLINE"}
    </div>
  );
};

export default FeederOnlineStatus;
