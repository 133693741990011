import moment from "moment-timezone";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";

import { PageWrapper } from "../components/app/PageWrapper";
import { Feeder, useQueryGetSiteById } from "../api";
import { FeederHealthStatus } from "../components/elements/FeederHealthStatus";
import { FeederCalibrationModal } from "../components/elements/FeederCalibrationModal";
import { LoadingComponent } from "../components/elements/LoadingComponent";
import { ErrorComponent } from "../components/elements/ErrorComponent";
import useQueryGetFeedersBySiteId from "../api/queries/GetFeedersBySiteId";
import FeederOnlineStatus from "../components/elements/FeederOnlineStatus";
import FeederState from "../components/elements/FeederState";
import FeederProximity from "../components/elements/FeederProximity";
import FeederTub from "../components/elements/FeederTub";
import FeederPlatform from "../components/elements/FeederPlatform";
import FeederRFID from "../components/elements/FeederRFID";
import { FeederCard } from "../components/elements/FeederCard";
import { FeederList } from "../components/lists/FeederList";

export const FeedersPage = () => {
  const params = useParams();
  const siteId = params.siteId;
  const navigate = useNavigate();
  const { loading, error, response } = useQueryGetSiteById(siteId);
  const {
    loading: loadingFeeders,
    error: errorFeeders,
    response: responseFeeders,
  } = useQueryGetFeedersBySiteId(siteId);

  const [inputText, setInputText] = useState("");

  const filteredFeeders = useMemo(() => {
    const filteredData = responseFeeders.feeders.filter((el) => {
      //if no input the return the original
      if (inputText === "") {
        return el;
      }
      //return the item which contains the user input
      else {
        return el.name.toLowerCase().includes(inputText);
      }
    });
    return filteredData;
  }, [inputText, responseFeeders.feeders]);

  if (loading || loadingFeeders) {
    return <LoadingComponent></LoadingComponent>;
  }

  if (error || !response.isSuccess) {
    return (
      <ErrorComponent error={error} message={response.message}></ErrorComponent>
    );
  }

  if (errorFeeders || !responseFeeders.isSuccess) {
    return (
      <ErrorComponent
        error={errorFeeders}
        message={responseFeeders.message}
      ></ErrorComponent>
    );
  }

  let inputHandler = (e: any) => {
    //convert input text to lower case
    var searchText = e.target.value.toLowerCase();
    setInputText(searchText);
  };

  const site = response.site;

  const onClickTrials = () => {
    navigate(`/sites/${siteId}/trials`);
  };

  return (
    <PageWrapper>
      <div className="ml-4">
        <div className="flex">
          <div className=" flex items-center justify-between pb-3">
            <div>
              <h2 className="text-gray-600">
                <Link className="cursor-pointer font-semibold" to="/sites">
                  Sites:
                </Link>{" "}
                <Link
                  className="cursor-pointer font-semibold"
                  to={`/sites/${site.id}`}
                >
                  {site.name}:
                </Link>{" "}
                Manage Feeders
              </h2>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex ml-auto">
            <div className="ml-auto flex bg-gray-50 p-2 rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
              <input
                className="bg-gray-50 outline-none ml-1 block "
                type="text"
                onChange={inputHandler}
                name=""
                id=""
                placeholder="search..."
              />
            </div>
          </div>
        </div>
        <div>
          <FeederList feeders={filteredFeeders}></FeederList>
        </div>
      </div>
    </PageWrapper>
  );
};

export default FeedersPage;
