import { Feeder } from "../../api/interfaces";

interface FeederProximityProps {
  feeder: Feeder;
}

export const FeederProximity = ({ feeder }: FeederProximityProps) => {
  let state = null;

  switch (feeder.controller.proximityState) {
    case "DETECTED":
      state = <span className="text-orange-500">Detected</span>;
      break;
    case "CLEAR":
      state = <span className="text-green-500">Clear</span>;
      break;
    default:
      state = (
        <span className="text-green-500">
          {feeder.controller.proximityState}
        </span>
      );
  }

  return (
    <span className=" text-sm">
      <span className="font-semibold">Proximity:</span>{" "}
      {feeder.controller.proximityDistance} mm ({state})
    </span>
  );
};

export default FeederProximity;
