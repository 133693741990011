import { Feeder } from "../../api/interfaces";

interface FeederStateProps {
  feeder: Feeder;
}

export const FeederState = ({ feeder }: FeederStateProps) => {
  let colour = "bg-blue-500";

  switch (feeder.controller.state) {
    case "IDLE":
      colour = "bg-green-500";
      break;
    case "CALIBRATING":
      colour = "bg-pink-500";
      break;
    default:
  }

  return (
    <div
      className={`text-sm rounded-sm px-1 inline-flex items-center text-base font-semibold ${colour} text-white dark:text-white`}
    >
      {feeder.controller.state}
    </div>
  );
};

export default FeederState;
