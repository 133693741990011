import moment from "moment-timezone";
import { Feeder, Site } from "../../api";
import FeederState from "../elements/FeederState";
import FeederTub from "../elements/FeederTub";
import FeederPlatform from "../elements/FeederPlatform";
import FeederProximity from "../elements/FeederProximity";
import FeederRFID from "../elements/FeederRFID";
import FeederOnlineStatus from "../elements/FeederOnlineStatus";
import FeederFirmwareVersion from "../elements/FeederFirmwareVersion";
import FeederIPAddress from "../elements/FeederIPAddress";
import { useEffect, useState } from "react";
import { FeederCalibrationModal } from "../elements/FeederCalibrationModal";

export interface FeederListProps {
  feeders: Feeder[];
}

export const FeederList = ({ feeders }: FeederListProps) => {
  const [showCalibrateModel, setShowCalibrateModal] = useState(false);
  const [selectedFeeder, setSelectedFeeder] = useState<Feeder>(null);

  useEffect(() => {
    if (selectedFeeder) {
      setSelectedFeeder({
        ...feeders.find((f) => f.id === selectedFeeder.id),
      });
    }
  }, [feeders]);

  return (
    <div className="bg-white rounded-md w-full">
      {showCalibrateModel ? (
        <FeederCalibrationModal
          onCancel={() => setShowCalibrateModal(false)}
          onSuccess={() => setShowCalibrateModal(false)}
          feeder={selectedFeeder}
        />
      ) : (
        <></>
      )}
      <div>
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Information
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {feeders.map((feeder) => (
                  <tr key={feeder.id}>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <div className="flex items-center">
                        <div className="ml-3">
                          <div className="text-gray-900 whitespace-no-wrap font-bold">
                            <FeederOnlineStatus
                              feeder={feeder}
                            ></FeederOnlineStatus>
                          </div>
                          <div className="text-gray-900 whitespace-no-wrap font-bold">
                            {feeder.name}
                          </div>
                          <div className="text-gray-900 whitespace-no-wrap">
                            {feeder.controller
                              ? feeder.controller.controllerId
                              : "UNKNOWN"}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {feeder.controller ? (
                        <div>
                          <div className="text-gray-900 whitespace-no-wrap">
                            <FeederIPAddress feeder={feeder}></FeederIPAddress>
                          </div>
                          <div className="text-gray-900 whitespace-no-wrap">
                            <FeederFirmwareVersion
                              feeder={feeder}
                            ></FeederFirmwareVersion>
                          </div>
                          <div className="mt-3 text-gray-500 whitespace-no-wrap">
                            {feeder.controller.isOnline
                              ? "Connected"
                              : "Disconnected"}
                            :{" "}
                            {moment(
                              new Date(feeder.controller.connectionEventTime)
                            ).fromNow()}
                          </div>
                          <div className="text-gray-500 whitespace-no-wrap">
                            Calibrated:{" "}
                            {moment(
                              new Date(feeder.controller.connectionEventTime)
                            ).fromNow()}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {feeder.controller ? (
                        <div>
                          <div className="mb-2 text-gray-900 whitespace-no-wrap">
                            <FeederState feeder={feeder}></FeederState>
                          </div>
                          <div className="text-gray-900 whitespace-no-wrap">
                            <FeederTub feeder={feeder}></FeederTub>
                          </div>
                          <div className="text-gray-900 whitespace-no-wrap">
                            <FeederPlatform feeder={feeder}></FeederPlatform>
                          </div>
                          <div className="text-gray-900 whitespace-no-wrap">
                            <FeederProximity feeder={feeder}></FeederProximity>
                          </div>
                          <div className="text-gray-900 whitespace-no-wrap">
                            <FeederRFID feeder={feeder}></FeederRFID>
                          </div>
                          <div className="mt-3 text-gray-500 whitespace-no-wrap">
                            Updated:{" "}
                            {moment(
                              new Date(feeder.controller.statusUpdateTime)
                            ).fromNow()}
                          </div>
                          <div className="text-gray-500 whitespace-no-wrap">
                            {moment(
                              new Date(feeder.controller.statusUpdateTime)
                            ).format("d MMM YYYY h:mm:ss A")}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {feeder.controller &&
                      feeder.controller.state === "IDLE" ? (
                        <div>
                          <span
                            className="text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500  cursor-pointer"
                            onClick={() => {
                              setSelectedFeeder(feeder);
                              setShowCalibrateModal(true);
                            }}
                          >
                            Calibrate
                          </span>
                          <span
                            className="ml-2 text-sm font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500  cursor-pointer"
                            onClick={() => {}}
                          >
                            Disable
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
