import { Feeder } from "../../api/interfaces";

interface FeederFirmwareVersionProps {
  feeder: Feeder;
}

export const FeederFirmwareVersion = ({
  feeder,
}: FeederFirmwareVersionProps) => {
  if (!feeder.controller) {
    return (
      <span>
        <span className="font-semibold">Firmware:</span> UNKNOWN
      </span>
    );
  }
  return (
    <span>
      <span className="font-semibold">Firmware:</span>{" "}
      {feeder.controller.firmwareVersion}
    </span>
  );
};

export default FeederFirmwareVersion;
