import { Feeder } from "../../api/interfaces";

interface FeederIPAddressProps {
  feeder: Feeder;
}

export const FeederIPAddress = ({ feeder }: FeederIPAddressProps) => {
  if (!feeder.controller) {
    return (
      <span>
        <span className="font-semibold">Firmware:</span> UNKNOWN
      </span>
    );
  }
  return (
    <span>
      <span className="font-semibold">IP Address:</span>{" "}
      {feeder.controller.ipAddress}
    </span>
  );
};

export default FeederIPAddress;
