import { Feeder } from "../../api/interfaces";

interface FeederTubProps {
  feeder: Feeder;
}

export const FeederTub = ({ feeder }: FeederTubProps) => {
  let state = null;

  switch (feeder.controller.tubState) {
    case "STABLE":
      state = <span className="text-green-500">OK</span>;
      break;
    case "EMPTY":
      state = <span className="text-red-500">Empty</span>;
      break;
    default:
      state = (
        <span className="text-green-500">{feeder.controller.tubState}</span>
      );
  }

  return (
    <span className=" text-sm">
      <span className="font-semibold">Tub:</span> {feeder.controller.tubWeight}{" "}
      grams ({state})
    </span>
  );
};

export default FeederTub;
